.logo {
  width: 25px;
  height: 25px;
}

.logoMobile {
  width: 20px;
  height: 20px;
}

.titleDisplay {
  display: flex;
  align-items: center;
  gap: 0.5em;
}
.titleItem {
  height: 25px;
}

.titleDisplay h3 {
  margin-bottom: 0 !important;
}

.integrationContainer {
  display: flex;
  flex-wrap: wrap;
}

.webChatItem {
  padding: 0 0.5em 0.5em 0;
  box-sizing: border-box;
}

.webChatUrl {
  background-color: var(--ion-color-step-50);
  padding: 0.8em;
  border-radius: 5px;
}

.navTabs a {
  padding: 0.5em 1em !important;
}
.tabContent {
  padding: 0.8em;
}

.integrationItem {
  flex: 0 1 33.33%;
  padding: 0 0.5em 0.5em 0;
  box-sizing: border-box;
}

@media screen and (max-width: 980px) {
  .integrationItem {
    flex: 0 1 50%;
  }
}

.integrationConnectedItem {
  flex: 1 1 100%;
  box-sizing: border-box;
  padding: 0px 10px 10px 0px;
}

.modalContent {
  padding: 2em;
}

.accountItem {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--visito-border-color);
}

.accountItem div {
  height: 50px;
  display: flex;
  align-items: center;
}
.accountItemTitle {
  display: flex;
  gap: 1em;
}

.inputHolder {
  margin-top: 1em;
}

.dbForm label {
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 7.5px;
}
