.chat-wrapper-page {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.chat-wrapper-page .chat-list {
  width: 33%;
  max-width: 410px;
  min-width: 370px;
  border-right: 1px solid var(--visito-border-color);
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.chat-wrapper-page .chat-list-items-holder {
  height: 100%;
  overflow: scroll;
  display: flex;
  flex-direction: column;
}

.chat-wrapper-page .chat-list .chat-list-header {
  border-bottom: 1px solid var(--visito-border-color);
}

.chat-wrapper-page .chat-list-header-review {
  padding-inline: 16px;
  padding-bottom: 9px;
  display: flex;
  justify-content: space-between;
}

.chat-wrapper-page .chat-list-header-review .connection-status {
  height: 26px;
}

.chat-wrapper-page .chat-list ion-searchbar input {
  background: unset;
  border: 1px solid var(--ion-border-color);
  font-size: 1rem;
}
@media (min-width: 600px) {
  ion-searchbar.search-is-active .searchbar-clear-button.sc-ion-searchbar-ios {
    display: block;
  }
}

.chat-wrapper-page .chat-interface .chat-interface-header .navigation {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.chat-wrapper-page
  .chat-interface
  .chat-interface-header
  .navigation
  .escalation-resolution {
  min-width: 150px;
  text-align: right;
}

.chat-wrapper-page .chat-list .chat-list-header-search {
  position: relative;
  padding: 8px 16px 8px 16px;
}

.chat-wrapper-page .chat-list ion-searchbar {
  height: auto;
  contain: unset;
  padding-left: 0px;
}

.chat-wrapper-page .chat-list .searchbar-search-icon.sc-ion-searchbar-ios {
  width: 18px;
}

.chat-wrapper-page .sc-ion-searchbar-ios-h {
  height: 45px;
}

.chat-wrapper-page .chat-list ion-item-divider {
  border-bottom: 1px solid var(--visito-border-color);
  font-weight: 800;
  color: var(--ion-color-step-500);
}

.chat-wrapper-page .chat-presentation {
  display: flex;
  width: 100%;
  height: 100%;
}
.chat-wrapper-page .chat-interface {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.chat-wrapper-page .chat-welcome {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.chat-wrapper-page .chat-welcome .welcome-content {
  max-width: 700px;
}

.chat-wrapper-page .chat-interface .chat-interface-header {
  padding: 16px;
  border-bottom: 1px solid var(--visito-border-color);
  width: 100%;
}

.chat-wrapper-page .half-item .badge:not(:first-child) {
  margin-left: 2px; /* Adjust the margin-left as per your design */
}
.chat-wrapper-page
  .chat-interface
  .chat-interface-header
  .chat-header-contact-info {
  display: flex !important;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.chat-wrapper-page
  .chat-interface
  .chat-interface-header
  .chat-header-contact-info
  .user-info {
  font-size: 18px;
  font-weight: 500;
}

.chat-wrapper-page
  .chat-interface
  .chat-interface-header
  .chat-header-contact-info
  .user-platform {
  width: 20px;
  height: 20px;
}

.chat-wrapper-page
  .chat-interface
  .chat-interface-header
  .chat-header-contact-info
  .user-platform,
.chat-wrapper-page
  .chat-interface
  .chat-interface-header
  .chat-header-contact-info
  .user-info,
.chat-wrapper-page
  .chat-interface
  .chat-interface-header
  .chat-header-contact-info
  .user-online-status {
  margin-right: 7px;
}

.chat-wrapper-page .chat-interface .chat-interface-header .contact-tools {
  color: var(--ion-color-step-500);
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.chat-wrapper-page .chat-interface .chat-interface-body {
  height: 100%;
  overflow: scroll;
}

.chat-wrapper-page .chat-interface .chat-interface-footer {
  padding: 12px 12px 12px 12px;
}

.chat-wrapper-page .chat-interface .chat-input {
  display: flex;
  align-items: center;
}

.chat-wrapper-page .chat-tools {
  width: 0%;
  display: flex;
  flex-direction: column;
}

.chat-wrapper-page .chat-tools .chat-tools-body {
  overflow: scroll;
}

.chat-wrapper-page .chat-tools.open {
  border-left: 1px solid var(--visito-border-color);
  width: 33%;
  max-width: 350px;
  min-width: 250px;
}

.chat-wrapper-page .chat-tools .chat-tools-header {
  padding: 16px 16px;
  height: 60px;
  border-bottom: 1px solid var(--visito-border-color);
  display: flex;
  align-items: center;
}

.chat-wrapper-page .chat-tools .chat-tools-header .chat-tools-close {
  margin-right: 25px;
  cursor: pointer;
}
