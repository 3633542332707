@font-face {
  font-family: "Visito";
  src: url("./fonts/Outfit-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "Jakarta";
  src: url("./fonts/jakarta/PlusJakartaSans-VariableFont_wght.ttf")
    format("truetype");
}
:host(.select-justify-space-between) .select-wrapper {
  justify-content: center !important;
}

.flex {
  display: flex;
}

.flex-center {
  display: flex;
  justify-content: center;
}
.flex-align-center {
  display: flex;
  align-items: center;
}

.flex-grow-2 {
  flex-grow: 2;
}

.relative {
  position: relative;
}

.logo {
  background-color: var(--visito-color-1);
  -webkit-mask: url("../theme/images/letters.svg") no-repeat;
  mask: url("../theme/images/letters.svg") no-repeat;
  width: 75px;
  height: 25px;
}

.page-wrapper a.link {
  text-decoration: none;
  font-weight: 400;
}
a,
.wmde-markdown a {
  font-weight: 400;
  color: var(--ion-color-step-900);
}

a.link-unset {
  text-decoration: unset;
  color: unset;
}

.pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

table th {
  color: var(--ion-color-step-800);
}

table td {
  color: var(--ion-color-step-900);
}

table th,
table td,
table tr {
  border-color: var(--ion-border-color);
}

.logo-font {
  font-family: "Visito", sans-serif;
  color: var(--visito-color) !important;
}

.ion-content-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

ion-button {
  margin-inline: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

ion-tab-bar {
  padding-top: 6px;
}

.footer-ios ion-toolbar:first-of-type {
  --border-width: 0;
}

sound {
  height: 30px;
}

.page-wrapper {
  height: 100%;
}

.page-wrapper .page-title {
  font-size: 1.6em;
  display: flex;
  align-items: center;
  margin-bottom: 1em;
  margin-top: 0.75em;
  font-weight: 600;
  color: var(--ion-color-step-800);
}

.page-wrapper .page-title svg {
  margin-right: 5px;
}

.view-wrapper {
  width: 100%;
}

.view-wrapper .view-pane {
  margin: 16px;
  overflow: auto;
}
.view-wrapper .view-pane .view-pane-notes {
  color: var(--ion-color-step-600);
  padding: 0em 1em;
}

.view-wrapper .view-pane-body {
  background: var(--ion-background-color);
  border-radius: 7px;
  padding: 1em;
}

.view-wrapper .view-pane-title {
  font-size: 0.9em;
  color: var(--ion-color-step-800);
  margin-left: 31px;
  margin-bottom: 5px;
  font-weight: 500;
  text-transform: uppercase;
}

.action-dots {
  color: var(--ion-color-step-600) !important;
}
.action-dots svg {
  width: 18px;
}
.action-dots:hover {
  color: var(--ion-color-step-800);
}

.visito-card {
  overflow: hidden;
  border-radius: 7px;
  border: 1px solid var(--visito-chat-input-border);
  background-color: var(--visito-background-color);
}

.visito-card.full-width {
  width: 100%;
}

.visito-card-title {
  font-size: 16px;
  font-weight: 600 !important;
  color: var(--ion-color-step-800);
  margin: 0px 0px;
}

.visito-card-header {
  padding: 0.8em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--visito-border-color);
  background-color: var(--ion-color-step-50);
}

.visito-card-body {
  padding: 1em;
  background-color: var(--visito-background-color);
}

.visito-card-footer {
  padding: 0.8em;
  border-top: 1px solid var(--visito-border-color);
}

@media (max-width: 600px) {
  .visito-card-body {
    background-color: var(--ion-item-background);
  }
}

.visito-card-list {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.visito-card-body.bg-suttle {
  background-color: rgb(var(--ion-color-primary-rgb-subtle));
}

.visito-card-body.np {
  padding: 0;
}

.page-pane {
  background: var(--ion-background-color);
  overflow: hidden;
  border-radius: 0.5em;
}

.page-pane ion-list {
  padding: 0 !important;
  margin: 0 !important;
}

.title-large {
  padding-top: 5px !important;
}

/*
ion-item {
  --background: unset;
}
*/

.page-pane .pane-header {
  padding: 12px 16px;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  border-bottom: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color);
  display: flex;
  align-items: center;
}

.pane-header.between {
  justify-content: space-between;
}

.page-pane .pane-content.text {
  padding: 12px 16px;
}

.chart-container {
  width: 100%;
  min-height: 100px;
}

.chart-container .chart-div {
  width: 100%;
  height: 100%;
}

.highcharts-credits {
  display: none;
}

.highcharts-background {
  fill: transparent;
}

.highcharts-grid-line {
  stroke: var(--visito-chat-input-border);
}
.highcharts-axis-labels text {
  color: var(--ion-color-dark) !important;
  fill: var(--ion-color-dark) !important;
}

.w-md-editor-toolbar li > button > svg {
  vertical-align: inherit;
}

@media (max-width: 600px) {
  .header-soft-bg {
    --background: var(--ion-color-light) !important;
  }
}

/* search bar */
ion-searchbar {
  --cancel-button-color: var(--visito-color-2) !important;
}

/* Lists 
ion-item {
  --background-hover: unset;
}
*/

/* Navigation Mobile */
ion-tab-button.visito-tabs {
  --color-selected: var(--visito-color-1);
}

ion-back-button {
  --color: var(--visito-color-2);
}

ion-toolbar ion-button::part(native) {
  --color: var(--visito-co);
}

/* FORMS */

@media screen and (min-width: 600px) {
  ion-toggle {
    --handle-width: 17px;
  }
  ion-toggle::part(track) {
    width: 35px;
    height: 22px;
  }
}

@media screen and (max-width: 600px) {
  ion-toggle {
    --handle-width: 20px;
  }
  ion-toggle::part(track) {
    width: 38px;
    height: 25px;
  }
}

ion-toggle {
  --track-background-checked: var(--visito-color-1) !important;
  --handle-background-checked: var(--ion-color-light);
  @media (prefers-color-scheme: dark) {
    --track-background-checked: var(--visito-color-1-subtle) !important;
    --handle-background-checked: var(--ion-color-dark);
  }
}

hr {
  border-color: var(--ion-color-step-100);
  opacity: 1;
}

@media screen and (min-width: 600px) {
  hr {
    border-color: var(--visito-border-color);
    opacity: 1;
  }
}

/* Paddings & Margins */
.p-6 {
  padding: 1.5rem;
}

/* Modal */
.modal-footer,
.modal-header {
  border-top: none;
  border-bottom: none;
}
.modal-content {
  background-color: var(--ion-background-color);
  color: var(--ion-color-dark);
}
.modal-header .btn-close {
  color: var(--ion-color-dark);
}

@media (prefers-color-scheme: dark) {
  .modal-header .btn-close {
    -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
    filter: invert(100%);
  }
}

.in-toolbar {
  contain: unset;
}

/* Progress Bars */
.visito-progress-bar {
  height: 4px;
}

.visito-progress-bar .progress-bar {
  background-color: var(--visito-color-1);
}

/* Segments */

ion-segment.visito {
  --background: var(--visito-color-1-light);
}

ion-segment-button.visito {
  --color-checked: var(--ion-color-step-0);
  --indicator-color: var(--visito-color-1);
  --color-hover: var(--visito-color-1);
}

/* BUTTONS */

.btn-xs {
  --bs-btn-padding-y: 0.2rem;
  --bs-btn-padding-x: 0.2rem;
  --bs-btn-font-size: 0.775rem;
  --bs-btn-border-radius: 0.25rem;
}

.btn {
  font-weight: 500;
}

.btn-flex {
  display: flex;
  align-items: center;
  gap: 0.5em;
  padding-inline: 0.5em;
}

.btn:disabled {
  border-color: var(--ion-color-step-150);
  background-color: var(--ion-color-step-50);
}

.btn-visito-primary,
.btn-visito-primary:active {
  background-color: var(--visito-color-1) !important;
  border-color: var(--visito-color-1) !important;
  color: var(--ion-color-light) !important;
}

.btn-visito-primary:hover {
  background-color: var(--visito-color-1-hover);
  border-color: var(--visito-color-1-hover);
  color: var(--ion-color-light);
}

.btn-visito-primary-border {
  background-color: var(--visito-color-1-light);
  border-color: var(--visito-color-1);
  color: var(--visito-color-1);
}

.btn-visito-primary-border:hover {
  background-color: var(--visito-color-1-light);
  border-color: var(--visito-color-1);
  color: var(--visito-color-1);
}

.btn-visito-outline {
  border-color: var(--visito-color-1);
  color: var(--visito-color-1);
}

.btn-visito-outline:hover {
  background-color: var(--visito-color-1);
  border-color: var(--visito-color-1);
  color: var(--ion-background-color);
}

.btn-visito-primary-subtle {
  background-color: var(--visito-color-1-light);
  border-color: var(--visito-color-1-light);
  color: var(--ion-color-dark);
}

.btn-visito-primary-subtle:hover {
  background-color: var(--visito-color-1-subtle);
  border-color: var(--visito-color-1-subtle);
  color: var(--ion-color-dark);
}

.btn-visito-secondary {
  background-color: var(--visito-color-2);
  border-color: var(--visito-color-2);
  color: var(--ion-color-light);
}

.btn-visito-secondary:hover {
  background-color: var(--visito-color-2-hover);
  border-color: var(--visito-color-2-hover);
  color: var(--ion-color-light);
}

.btn-visito-tertiary {
  background-color: var(--visito-color-3);
  border-color: var(--visito-color-3);
  color: var(--ion-color-dark);
}

.btn-visito-tertiary:hover {
  background-color: var(--visito-color-3-hover);
  border-color: var(--visito-color-3-hover);
  color: var(--ion-color-light);
}

.btn-visito-light {
  background-color: var(--ion-color-step-0);
  border-color: var(--visito-border-color);
  color: var(--ion-color-step-750);
}

.btn-visito-light.nb {
  border-color: var(--ion-color-step-0);
}

.btn-visito-light:hover {
  background-color: var(--ion-color-step-50);
  color: var(--ion-color-step-850);
}

.btn-visito-light:active {
  border-color: var(--ion-color-step-250) !important;
}

.btn-visito-link {
  background-color: none;
  border-color: none;
  color: var(--visito-color-1);
}

.btn-visito-link:hover {
  color: var(--visito-color-1);
}

.btn-visito-link-danger {
  background-color: none;
  border-color: none;
  color: var(--visito-color-danger);
}

.btn-visito-link-danger:hover {
  color: var(--visito-color-danger);
}

.btn-visito-link-light {
  background-color: none;
  border-color: none;
  color: var(--ion-color-step-850);
}

.btn-visito-link-light:hover {
  color: var(--ion-color-step-850);
}

.btn-visito-white {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  border-radius: 0.5em;
  background-color: var(--visito-background-color);
  color: var(--ion-color-step-750);
  border: 1px solid var(--ion-color-step-150);
  padding: 0.2rem 0.2rem;
  font-size: 0.9rem;
  text-decoration: none;
}

.btn-ionic-light {
  background-color: var(--ion-color-step-0);
  border-color: var(--ion-border-color);
  color: var(--ion-color-step-750);
}

.btn-ionic-light:hover {
  background-color: var(--ion-color-step-50);
  color: var(--ion-color-step-850);
  border-color: var(--ion-border-color);
}

.btn-ionic-light:active {
  border-color: var(--ion-color-step-250) !important;
}

.btn-primary {
  background-color: var(--bs-blue);
  border-color: var(--bs-blue);
}

.btn-primary:hover {
  background-color: var(--bs-blue-hover);
  border-color: var(--bs-blue-hover);
}

.btn-warning {
  background-color: var(--visito-color-warning);
  border-color: var(--visito-color-warning);
}

.btn-warning:hover {
  background-color: var(--visito-color-warning);
  border-color: var(--visito-color-warning);
}

.btn-primary-subtle {
  background-color: rgb(var(--ion-color-primary-rgb-subtle));
  color: var(--ion-color-primary);
}

.btn-primary-subtle:hover {
  background-color: var(--bs-blue-hover);
  border-color: var(--bs-blue-hover);
  color: var(--ion-color-light);
}

.btn-light {
  border-color: var(--visito-color-4);
  background-color: var(--visito-color-4);
  color: var(--ion-color-step-900);
}

.btn-light:hover {
  border-color: var(--visito-color-4-hover);
  background-color: var(--visito-color-4-hover);
  color: var(--ion-color-step-900);
}

.btn-visito-danger {
  border-color: var(--visito-color-danger);
  background-color: var(--visito-color-danger);
  color: var(--ion-color-step-50);
}

.btn-visito-danger:hover {
  border-color: var(--visito-color-danger);
  background-color: var(--visito-color-danger);
  color: var(--ion-color-step-50);
}

.round-btn {
  cursor: pointer;
  min-height: 30px;
  min-width: 30px;
  border-radius: 100px;
  padding: 3px 6px;
}

.sqr-btn {
  cursor: pointer;
  min-height: 36px;
  min-width: 36px;
  padding: 3px 6px;
}

.round-btn.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
}

.btn.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.btn-suttle:hover {
  background-color: rgb(var(--ion-color-primary-rgb-subtle));
  color: var(--ion-color-primary);
}

.mr-1 {
  margin-right: 0.5rem;
}

.ml-1 {
  margin-left: 0.5rem;
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

/* font sizes */
.fs-0 {
  font-size: 0.65rem !important;
}

.fs-1 {
  font-size: 0.75rem !important;
}

.fs-2 {
  font-size: 0.85rem !important;
}

.fs-3 {
  font-size: 0.9rem !important;
}

.fs-4 {
  font-size: 1rem !important;
}

.fs-5 {
  font-size: 1.3125rem !important;
}
.fs-6 {
  font-size: 1.5rem !important;
}
.fs-7 {
  font-size: 1.75rem !important;
}

/* font weigts */

.fw-light {
  font-weight: 300 !important;
}

.fw-semibold-0 {
  font-weight: 400 !important;
}

.fw-semibold-1 {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 900 !important;
}

/* colors */

.bg-color-0 {
  background-color: var(--ion-background-color);
}

.color-step-50 {
  color: var(--ion-color-step-50);
}

.color-step-100 {
  color: var(--ion-color-step-100);
}

.color-step-150 {
  color: var(--ion-color-step-150);
}

.color-step-200 {
  color: var(--ion-color-step-200);
}

.color-step-250 {
  color: var(--ion-color-step-250);
}

.color-step-300 {
  color: var(--ion-color-step-300);
}

.color-step-350 {
  color: var(--ion-color-step-350);
}

.color-step-400 {
  color: var(--ion-color-step-400);
}

.color-step-450 {
  color: var(--ion-color-step-450);
}

.color-step-500 {
  color: var(--ion-color-step-500);
}

.color-step-550 {
  color: var(--ion-color-step-550);
}

.color-step-600 {
  color: var(--ion-color-step-600);
}

.color-step-650 {
  color: var(--ion-color-step-650);
}

.color-step-700 {
  color: var(--ion-color-step-700);
}

.color-step-750 {
  color: var(--ion-color-step-750);
}

.color-step-800 {
  color: var(--ion-color-step-800);
}

.color-step-850 {
  color: var(--ion-color-step-850);
}

.color-step-900 {
  color: var(--ion-color-step-900);
}

.color-step-950 {
  color: var(--ion-color-step-950);
}

.text-primary {
  color: var(--ion-color-primary);
}

.color-visito-1 {
  color: var(--visito-color-1);
}

.color-visito-2 {
  color: var(--visito-color-2);
}
.color-visito-3 {
  color: var(--visito-color-3);
}

.color-warning {
  color: var(--visito-color-warning);
}

.color-active {
  color: var(--visito-color-active);
}

.color-inactive {
  color: var(--visito-color-inactive);
}

.color-danger {
  color: var(--visito-color-danger);
}

.color-success {
  color: var(--visito-color-active);
}

.color-warning {
  color: var(--ion-color-warning);
}

.color-visito-2-subtle {
  color: var(--visito-color-2);
}

.color-visito-1-bg {
  background-color: var(--visito-color-1);
  color: var(--ion-color-step-50);
}

.color-visito-2-bg {
  background-color: var(--visito-color-2);
}

.color-visito-3-bg {
  background-color: var(--visito-color-3);
}

.form-check-input {
  background-color: var(--ion-color-step-0);
  border-color: var(--ion-color-step-150);
}

.form-check-input.primary:checked {
  background-color: var(--visito-color-1);
  border-color: var(--visito-color-1);
}

.form-check-input:focus {
  border-color: var(--ion-color-step-200);
  outline: 0;
  box-shadow: none;
}

.form-control:focus {
  background-color: #fff;
  border-color: var(--visito-color-3);
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(var(--visito-color-3-rgb), 0.4);
}

.form-control {
  background-color: var(--ion-color-step-0) !important;
  border: 1px solid var(--ion-color-step-100) !important;
  color: var(--ion-color-dark) !important;
}

@media (prefers-color-scheme: dark) {
  .form-control {
    background-color: var(--ion-color-step-100) !important;
  }
  .form-control:focus {
    box-shadow: none;
  }
  .form-check-input.primary:checked {
    background-color: var(--visito-color-1-subtle);
    border-color: var(--visito-color-1-subtle);
  }
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(
    var(--bs-primary-rgb),
    var(--bs-bg-opacity, 1)
  ) !important;
}

.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(
    var(--bs-secondary-rgb),
    var(--bs-bg-opacity, 1)
  ) !important;
}

.text-bg-success {
  color: var(--ion-color-dark) !important;
  background-color: var(--visito-color-1-subtle) !important;
}

.text-bg-info {
  color: #fff !important;
  background-color: RGBA(
    var(--bs-info-rgb),
    var(--bs-bg-opacity, 1)
  ) !important;
}

ion-toolbar.tool-bg-warning {
  --background: RGBA(
    var(--visito-color-bg-warning-subtle-rgb),
    var(--bs-bg-opacity, 1)
  ) !important;
}

.text-bg-warning {
  color: var(--visito-color-warning) !important;
  background-color: RGBA(
    var(--visito-color-bg-warning-rgb),
    var(--bs-bg-opacity, 1)
  ) !important;
}

.text-bg-info {
  color: var(--visito-color-purple) !important;
  background-color: RGBA(
    var(--visito-color-purple-light-rgb),
    var(--bs-bg-opacity, 1)
  ) !important;
}

.text-bg-warning-subtle {
  color: var(--visito-color-warning) !important;
  background-color: RGBA(
    var(--visito-color-bg-warning-subtle-rgb),
    var(--bs-bg-opacity, 1)
  ) !important;
}

.text-bg-danger {
  color: var(--visito-color-danger) !important;
  background-color: RGBA(
    var(--visito-color-bg-danger-subtle-rgb),
    var(--bs-bg-opacity, 1)
  ) !important;
}

.text-bg-light {
  color: var(--ion-color-step-900) !important;
  background-color: RGBA(
    var(--ion-color-step-50-rgb),
    var(--bs-bg-opacity, 1)
  ) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(
    var(--bs-dark-rgb),
    var(--bs-bg-opacity, 1)
  ) !important;
}

.text-bg-muted {
  color: #fff !important;
  background-color: RGBA(
    var(--bs-muted-rgb),
    var(--bs-bg-opacity, 1)
  ) !important;
}

.text-bg-indigo {
  color: #fff !important;
  background-color: RGBA(
    var(--bs-indigo-rgb),
    var(--bs-bg-opacity, 1)
  ) !important;
}

.text-bg-light-indigo {
  color: #000 !important;
  background-color: RGBA(
    var(--bs-light-indigo-rgb),
    var(--bs-bg-opacity, 1)
  ) !important;
}

/* Drop down menu */
.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 0.875rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: 7px;
  --bs-dropdown-border-width: 0;
  --bs-dropdown-inner-border-radius: calc(7px - 0);
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: #f6f9fc;
  --bs-dropdown-link-active-color: #2a3547;
  --bs-dropdown-link-active-bg: #f6f9fc;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 10px;
  --bs-dropdown-header-color: #2a3547;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--visito-background-color);
  background-clip: padding-box;
  border: 1px solid var(--visito-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  box-shadow: var(--bs-dropdown-box-shadow);
}

.dropdown-item {
  color: var(--ion-color-step-900);
}

.dropdown-item:focus,
.dropdown-item:hover {
  color: var(--visito-color-1) !important;
  background-color: unset;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--visito-border-color);
  opacity: 1;
}

/* Platforms */

.whatsapp-logo {
  background: var(--whatsapp-logo-url) no-repeat center center;
  background-size: contain;
}

.cloudbeds-logo,
.cb-logo {
  background: var(--cloudbeds-logo-url) no-repeat center center;
  background-size: contain;
}

.siteminder-logo,
.sm-logo {
  background: var(--siteminder-logo-url) no-repeat center center;
  background-size: contain;
}

.instagram-logo {
  background: var(--instagram-logo-url) no-repeat center center;
  background-size: contain;
}

.messenger-logo {
  background: var(--messenger-logo-url) no-repeat center center;
  background-size: contain;
}

.airbnb-logo {
  background: var(--airbnb-logo-url) no-repeat center center;
  background-size: contain;
}

.web-logo {
  background: var(--web-logo-url) no-repeat center center;
  background-size: contain;
  position: relative;
}

.web-logo::after {
  content: "";
  position: absolute;
  bottom: -3px; /* Adjust the spacing from bottom as needed */
  right: -3px; /* Adjust the spacing from right as needed */
  width: 9px;
  height: 9px;
  border-radius: 50%;
  border: 0.5px solid var(--visito-border-color);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2); /* Optional subtle shadow */
  background-color: var(--visito-color-inactive);
}

.web-logo.online::after {
  background-color: var(--visito-color-active);
}

.voice-logo {
  background: var(--voice-logo-url) no-repeat center center;
  background-size: contain;
}

.guesty-logo,
.gs-logo {
  background: var(--guesty-logo-url) no-repeat center center;
  background-size: contain;
}

.hostaway-logo,
.ha-logo {
  background: var(--hostaway-logo-url) no-repeat center center;
  background-size: contain;
}

.mirai-logo {
  background: var(--mirai-logo-url) no-repeat center center;
  background-size: contain;
}

.badge {
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 6px;
}

.badge.auto-capitalize {
  text-transform: capitalize;
}

.badge-visito {
  background: var(--visito-color-1);
}

.badge.with-icon {
  display: flex;
}

.breadcrumb-visito {
  font-size: 1em;
  display: flex;
  align-items: center;
  margin-bottom: 1.1em;
  margin-top: 0.8em;

  color: var(--ion-color-step-600);
}

.breadcrumb-visito a {
  color: var(--ion-color-step-600);
  text-decoration: none;
  font-weight: 400;
  display: flex;
  align-items: center;
}
.breadcrumb-visito svg {
  margin-right: 10px;
}

._contentEditable_11eqz_352 h1 {
  font-size: 1.5em;
}

._contentEditable_11eqz_352 h2 {
  font-size: 1.3em;
}
._contentEditable_11eqz_352 h3 {
  font-size: 1.1em;
}
._contentEditable_11eqz_352 h4 {
  font-size: 0.9em;
}

._contentEditable_11eqz_352 {
  color: var(--ion-color-dark);
  background-color: var(--ion-background-color);
}

._contentEditable_11eqz_352[contenteditable="false"] {
  background-color: var(--ion-color-step-50);
}

._readOnlyToolbarRoot_11eqz_168 {
  background: var(--ion-color-step-50) !important;
  pointer-events: auto;
  border-bottom: none !important;
}

._readOnlyToolbarRoot_11eqz_168 > div {
  opacity: 1;
}

._toolbarRoot_11eqz_145 {
  color: var(--ion-color-step-600);
  background-color: var(--ion-color-step-0);
  border-bottom: 1px solid var(--visito-border-color);
  border-radius: 0px !important;
}
._selectTrigger_11eqz_281 {
  background-color: var(--ion-color-step-100);
}
._selectTrigger_11eqz_281 span {
  color: var(--ion-color-step-800) !important;
}
._selectContainer_11eqz_261 {
  background-color: var(--ion-color-step-100);
  color: var(--ion-color-step-800);
}

._readOnlyToolbarRoot_11eqz_168 .text-editor-extra {
  opacity: 1;
  pointer-events: all;
}

._readOnlyToolbarRoot_11eqz_168 ._tooltipTrigger_11eqz_630,
._readOnlyToolbarRoot_11eqz_168 #styles,
._readOnlyToolbarRoot_11eqz_168 #list {
  display: none;
}

@media screen and (max-width: 600px) {
  ._toolbarRoot_11eqz_145 {
    border-radius: 0px !important;
  }
}

.no-shadow {
  box-shadow: none;
}

.no-underline {
  text-decoration: none;
}

.underline {
  text-decoration: underline;
}

.alert-button-group.sc-ion-alert-ios {
  gap: 8px;
  flex-wrap: nowrap;
  justify-content: center;
  padding: 0 4px;
  padding-bottom: 12px;
}

.alert-button-inner.sc-ion-alert-md {
  justify-content: center;
}

.breadcrumb-active {
  color: var(--ion-color-step-700) !important;
  font-weight: normal !important;
}

.breadcrumb-parent {
  color: var(--visito-color-1) !important;
}

.react-datepicker {
  border-color: var(--ion-color-step-200) !important;
}

.react-datepicker__header {
  background-color: var(--ion-color-step-100) !important;
  border-bottom-color: var(--ion-color-step-200) !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__day-name {
  color: var(--ion-text-color) !important;
}
.react-datepicker-popper {
  z-index: 10;
}
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  fill: var(--ion-color-step-100) !important;
  color: var(--ion-color-step-100) !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: var(--visito-color-inactive) !important;
}

.react-datepicker__day--in-range {
  background-color: var(--visito-color-1) !important;
  color: var(--visito-background-color) !important;
}

.react-datepicker__day--in-selecting-range:not(
    .react-datepicker__day--in-range
  ) {
  background-color: var(--visito-color-1) !important;
  color: var(--visito-background-color) !important;
}

.react-datepicker__navigation-icon::before {
  border-color: var(--ion-color-step-500) !important;
}

[aria-checked="true"].sc-ion-alert-md .alert-radio-icon.sc-ion-alert-md {
  border-color: var(--visito-color-1) !important;
}

.alert-radio-inner.sc-ion-alert-md {
  background-color: var(--visito-color-1) !important;
}

#alert-delete-integration .alert-button-role-cancel {
  color: var(--ion-color-step-500) !important;
}
#alert-delete-integration .alert-button-role-confirm {
  color: var(--visito-color-danger) !important;
}

.alert-button.sc-ion-alert-md {
  color: var(--visito-color-1);
}

.description {
  color: var(--ion-color-step-700);
}

.pl-0 {
  padding-left: 0;
}

.w-max {
  width: max-content;
}

.h-90 {
  height: 90%;
}

.w-20 {
  width: 20%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.ml-auto {
  margin-left: auto;
}

.max-w-full {
  max-width: 100%;
}

.max-h-full {
  max-height: 100%;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.overflow-y-none {
  overflow-y: hidden;
}

.break-all {
  word-break: break-all;
}

.object-contain {
  object-fit: contain;
}

.alertConfirm {
  background-color: var(--visito-color-1) !important;
  color: #fff !important;
}

.alertCancel {
  border: 1px solid var(--visito-chat-input-border) !important;
  background-color: var(--visito-background-color) !important;
  color: var(--ion-color-step-650) !important;
}

.disabled {
  border-color: var(--ion-color-step-150) !important;
  background-color: var(--ion-color-step-50) !important;
  color: var(--ion-color-step-650) !important;
  pointer-events: none !important;
}

.no-radius {
  border-radius: 0px;
}

.no-border {
  border: none;
}

.display-none {
  display: none;
}

.default-bg {
  background-color: var(--ion-color-step-0);
}

.no-hover:hover {
  color: inherit;
}

.no-focus:focus {
  outline: none;
  box-shadow: none;
  border: none;
}

.no-bg {
  background-color: transparent;
}

/**
  Templates modal
**/
ion-modal#templates-modal {
  --width: 100vw;
  --min-width: 250px;
  --height: 100vh;
  --border-radius: 0px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

ion-modal#templates-modal .wrapper {
  width: 100%;
  max-height: 100vh;
  overflow-y: auto;
}

/**
  New conversation modal
**/
ion-modal#new-conversation-modal {
  --width: 35vw;
  --min-width: 250px;
  --height: auto;
  --border-radius: 0.6rem;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

ion-modal#new-conversation-modal .inner-content {
  width: 100%;
  max-height: 98vh;
  overflow-y: auto;
}

/**
  Crawling completed modal
**/
ion-modal#crawling-completed-modal {
  --width: 28vw;
  --height: auto;
  --border-radius: 1rem;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

ion-modal#crawling-completed-modal .inner-content {
  width: 100%;
  max-height: 98vh;
  overflow-y: auto;
}

/**
  Plaground modal
**/
ion-modal#playground-info {
  --width: 35vw;
  --min-width: 250px;
  --height: auto;
  --border-radius: 0.6rem;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

ion-modal#playground-info .inner-content {
  width: 100%;
  max-height: 98vh;
  overflow-y: auto;
}

ion-modal#playground-info-mobile {
  --width: 90vw;
  --height: auto;
  --border-radius: 0.6rem;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

ion-modal#playground-info-mobile .inner-content {
  width: 100%;
  max-height: 98vh;
  overflow-y: auto;
}

ion-modal#integrations-modal {
  --ion-background-color: var(--ion-color-light);
  --background-hover: unset;
  --ion-toolbar-background: var(--ion-color-step-0);
  --ion-toolbar-border-color: var(--ion-color-step-50);
}
ion-modal#integrations-modal ion-item {
  --background-hover: unset;
}

ion-toast.success-toast,
ion-toast.error-toast {
  --box-shadow: none;
}
ion-toast.success-toast::part(container) {
  background: var(--visito-color-success);
  border: 1px solid var(--visito-color-success-border);
  border-radius: 0.4rem;
  color: #333333;
}
ion-toast.error-toast::part(container) {
  color: var(--visito-color-danger);
  background-color: RGBA(
    var(--visito-color-bg-danger-subtle-rgb),
    var(--bs-bg-opacity, 1)
  );
  border: 1px solid var(--visito-color-danger);
  border-radius: 0.4rem;
  font-weight: 500;
}

ion-segment.context-segment {
  --background: var(--ion-color-step-0);
  font-size: 1rem;
}
ion-segment-button.context-button {
  --indicator-color: var(--ion-color-step-0);
  --border-radius: 0px;
  --indicator-box-shadow: none;
  --color: var(--ion-color-step-750);
  --color-checked: var(--visito-color-1);
  --color-focused: var(--visito-color-1);
  --color-hover: var(--visito-color-1);
  --background-hover-opacity: 1;
  --background-focused-opacity: 1;
  font-size: 0.95rem;
}
ion-segment-button.context-button:before {
  border-left: var(--border-width) var(--border-style) var(--ion-color-step-400);
  transition: none;
}

ion-segment-button.context-button:first-of-type:before {
  border-left-color: transparent;
}

ion-segment-button.context-button.segment-button-checked {
  font-weight: 500;
}

ion-segment-button.context-button.segment-button-after-checked::before,
ion-segment-button.context-button.segment-button-checked:before {
  opacity: 1 !important;
}

/**
  Phone input
**/
.PhoneInput input {
  outline: none;
  width: 100%;
  font-size: 14px;
  background-color: unset;
  border: 1px solid var(--visito-chat-input-border);
  border-radius: 8px;
  padding: 8px 12px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.PhoneInputCountry {
  border: 1px solid var(--visito-chat-input-border);
  border-radius: 8px;
  padding: 8px 12px;
  margin-right: 0 !important;
  border-right: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  appearance: none;
  outline: none;
}

.PhoneInputCountrySelectArrow {
  display: none !important;
}

/**
  New markdown editor
**/
.ProseMirror {
  outline: none;
  padding-bottom: 2.5rem;
}

.is-empty::before {
  content: attr(data-placeholder);
  color: var(--ion-color-step-500);
  font-style: italic;
  position: absolute;
  left: 16px;
  top: 12px;
  pointer-events: none;
  display: block;
  width: 100%;
  height: auto;
  white-space: nowrap;
  opacity: 0.6;
}

.ProseMirror:not(.is-empty)::before {
  display: none;
}

.ProseMirror p[data-placeholder]::before {
  display: none;
}

.editor-content-v2 h1 {
  font-size: 2rem !important;
}

.editor-content-v2 h2 {
  font-size: 1.75rem !important;
}

.editor-content-v2 h3 {
  font-size: 1.5rem !important;
}

.editor-content-v2 h3 {
  font-size: 1rem !important;
}

.search-result {
  background-color: var(--visito-color-1);
  border-radius: 3px;
  padding: 2px 4px;
  transition: background-color 0.3s ease-in-out;
  box-shadow: 0px 0px 5px rgba(var(--visito-color-1-rgb), 0.7);
  color: var(--ion-color-step-0);
  font-weight: bold;
}
