.alertContainer {
  background-color: var(--ion-color-step-200);
  padding: 0.4rem 0.5rem;
  border-radius: 0.4rem;
  font-size: 0.85rem;
  display: flex;
  align-items: center;
  gap: 0.4rem;
}

.dangerAlert {
  background-color: var(--visito-color-danger-light);
}

.infoAlert {
  background-color: var(--visito-color-purple-light);
}