.progress-info {
  padding: 8px 0;
  display: flex;
  align-items: center;
  gap: 16px;
  width: 100%;
  justify-content: space-between;
  margin: 0;
}
.progress-xxs {
  height: 3px !important;
}
