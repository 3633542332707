.chat-detail-content {
  padding: 1em 1em;
  overflow: visible;
}
.time-badge {
  padding: 30px 0px;
  text-align: center;
  text-transform: uppercase;
}

.time-badge ion-badge {
  font-weight: 400 !important;
}

.chat-wrapper {
  display: flex;
}

.chat-wrapper:not(:last-child) {
  margin-bottom: 12px;
}

.chat-wrapper.right {
  justify-content: flex-end;
}

.chat-detail-playground .chat-wrapper.right,
.chat-detail-web-chat .chat-wrapper.right {
  justify-content: flex-start;
}

.chat-detail-playground .chat-wrapper,
.chat-detail-web-chat .chat-wrapper {
  justify-content: flex-end;
}

.chat-holder {
  position: relative;
  max-width: 85%;
}

@media (min-width: 600px) {
  .chat-item.interactive {
    max-width: 400px;
  }
  .chat-item .interactive-call,
  .chat-item .interactive-audio {
    width: 350px;
    max-width: 100%;
  }
}

.chat-item {
  user-select: text;
  padding: 10px 10px;
  background-color: var(--visito-color-2-light);
  border-radius: 12px 12px 12px 0px;
  color: var(--ion-color-step-800);
  position: relative;
}

.chat-item .chat-item-options {
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 15;
}

.chat-item .chat-item-options .dropdown-item {
  padding: 0.2em 1em;
  font-weight: 400 !important;
}

.chat-item .chat-item-options .btn-actions {
  --bs-btn-padding-y: 0.2rem;
  --bs-btn-padding-x: 0.2rem;
  --bs-btn-font-size: 0.6rem;
  --bs-btn-border-radius: 0.5rem;
}

.highlight {
  animation: highlight-animation 1s ease-in-out 0s 2;
}

/* Animate from normal brightness to a brighter state and back */
@keyframes highlight-animation {
  0% {
    filter: brightness(100%);
  }
  50% {
    filter: brightness(50%);
  }
  100% {
    filter: brightness(100%);
  }
}

.chat-actions {
  margin-top: 4px;
}

.chat-actions ion-button:not(:last-child) {
  margin-bottom: 4px;
}
.chat-actions .chat-select-wrapper {
  border-radius: 8px;
  background-color: #f5f6f8;
  display: flex;
  justify-content: center;
}
.chat-actions ion-select::part(placeholder),
.chat-actions ion-select::part(icon) {
  font-size: 12px;
}

.chat-actions ion-select {
  min-height: 32px;
}
.chat-item p {
  margin-bottom: 0px;
  font-size: 0.9rem;
  text-align: left;
  @media (max-width: 600px) {
    font-size: 1rem;
  }
}

.right .chat-item {
  background-color: var(--ion-color-light);
  text-align: right;
  border-radius: 12px 12px 0px 12px;
  margin-left: 0;
}

.escalated .chat-item {
  background-color: rgb(var(--visito-color-bg-warning-subtle-rgb));
}

.escalated .chat-item:before {
  border-right-color: rgb(var(--visito-color-bg-warning-subtle-rgb));
  border-top-color: rgb(var(--visito-color-bg-warning-subtle-rgb));
}

.chat-item-time {
  text-align: right;
  font-size: 0.7em;
  font-weight: 400;
  padding-left: 12px;
  float: right;
  height: 12px;
  padding-top: 4px;
}

.chat-item-error {
  font-size: 0.7em;
  font-weight: 400;
  height: auto;
  padding-top: 4px;
}

.chat-item-time svg {
  margin-left: 4px;
}

.chat-item-time .read {
  color: var(--visito-color-3);
}

.chat-item-time .failed {
  color: var(--ion-color-danger-tint);
}

.interactive-img {
  cursor: pointer;
  min-height: 100px;
}

.interactive-audio {
  width: 100%;
  height: 35px;
  margin-bottom: 2px;
}

.interactive-call {
  display: flex;
  margin-bottom: 2px;
  gap: 1em;
  align-items: center;
}

.interactive-audio audio,
.interactive-call audio {
  height: 32px;
  width: 100%;
}

.interactive-call .call-icon {
  color: var(--visito-color-warning) !important;
  background-color: RGBA(
    var(--visito-color-bg-warning-rgb),
    var(--bs-bg-opacity, 1)
  ) !important;
  border-radius: 20px;
  padding: 0.35em 0.45em;
}

.interactive-call-summary {
  text-align: left;
  margin-top: 0.5em;
}
.interactive-call-summary hr {
  margin: 0.5em 0;
}
.interactive-img img {
  border-radius: 8px;
  margin-bottom: 6px;
  max-height: 375px;
}

.interactive-video {
  max-width: 330px;
}

.interactive-document {
  max-width: 280px;
}

@media (min-width: 380px) {
  .interactive-document {
    max-width: 350px;
  }
}

.interactive-video video,
.interactive-document iframe {
  width: 100%;
}

.interactive-file {
  a {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}
.download-btn {
  position: absolute;
  top: 35%;
}

.download-btn.left {
  left: -10%;
}

.download-btn.right {
  right: -10%;
}

.playground-badge {
  background-color: var(--visito-color-purple-light);
  color: var(--visito-color-purple);
  font-weight: 600;
  padding: 0.4rem;
  border-radius: 0.5rem;
  font-size: 0.8rem;
  margin-bottom: 1rem;
}
