.accountSwitcher :global(.nav-link) {
  border: 1px solid var(--visito-chat-input-border);
  border-radius: 8px;
  padding: 8px 12px !important;
  margin-left: 0 !important;
}

.accountSwitcher :global(.nav-link:after) {
  right: 16px;
  top: 16px;
  position: absolute;
}

.accountSwitcher :global(.dropdown-menu.show) {
  max-height: 35vh;
  width: 100%;
  overflow: scroll;
}

.accountSwitcher :global(.selected),
.accountSwitcher :global(.selected:hover) {
  display: flex;
  align-items: center;
  font-weight: bold;
  background-color: var(--ion-color-step-50);
}

.header {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  border-bottom: 1px solid var(--ion-color-step-100);
  padding: 1rem;
}

.footer {
  width: 100%;
  display: flex;
  gap: 0.5rem;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  border-top: 1px solid var(--ion-color-step-100);
}

.body {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  padding: 1rem;
  padding-bottom: 2rem;
}

.input {
  outline: none;
  width: 100%;
  font-size: 14px;
  background-color: unset;
  resize: none !important;
  border: 1px solid var(--visito-chat-input-border);
  border-radius: 8px;
  padding: 8px 12px;
}

/*
.nav-item.light-account-switcher .nav-link {
  border: 1px solid var(--visito-chat-input-border);
  border-radius: 8px;
  padding: 8px 12px !important;
  margin-left: 0 !important;
}

.nav-item.light-account-switcher .nav-link:after {
  right: 16px;
  top: 16px;
  position: absolute;
}

.nav-item.light-account-switcher .dropdown-menu.show {
  max-height: 35vh;
  width: 100%;
  overflow: scroll;
}
*/
