.overlayContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(var(--ion-color-step-1000-rgb), 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: inherit;
  z-index: 1;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}


.overlayContent {
  background: var(--ion-color-step-0);
  padding: 2rem 3rem;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(var(--ion-color-step-1000-rgb), 0.1);
  text-align: center;
  width: 55%;
  animation: fadeIn 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
