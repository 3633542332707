.headerContainer {
  border-bottom: 1px solid var(--visito-border-color) !important;
  padding: 1rem 1.5em !important;
}

.webChatWrapper {
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.webChatDetailHeader {
  padding: 1em;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--visito-border-color);
}

.webChatDetailFooter {
  display: flex;
  padding: 0.65em;
  color: var(--ion-color-step-500);
  background-color: var(--ion-color-step-50);
  justify-content: center;
  font-size: 13px;
}

.webChatDetailFooter a,
.webChatDetailFooter a:after,
.webChatDetailFooter a:before,
.webChatDetailFooter a:hover {
  color: var(--ion-color-step-700);
}
