.navbar {
  --bs-navbar-padding-y: 0rem !important;
}
.navbar-nav {
  --bs-nav-link-padding-y: 13px !important;
}
nav .dropdown-menu[data-bs-popper] {
  right: 0;
  left: unset;
}

nav .hidden {
  display: none;
}

nav .dropdown-menu {
  border-color: var(--visito-border-color);
}

.nav-link {
  color: var(--ion-color-dark) !important;
  font-weight: 500 !important;
  font-size: 15px !important;
  margin-left: 0.2rem !important;
  margin-right: 0.2rem !important;
  border-bottom: 1.5px solid transparent;
  padding: 0.8rem 1rem !important;
}

.nav-link:hover {
  color: var(--visito-color-1) !important;
  background-color: var(--color-step-100);
}

.visito-navbar .nav-link.active {
  color: var(--visito-color-1) !important;
  border-bottom: 1.5px solid var(--visito-color-1);
}

.nav-tabs .nav-link.active {
  background-color: var(--visito-background-color) !important;
  border-bottom: solid 1px var(--visito-background-color) !important;
}

.account-switcher .nav-link {
  padding: 0.3rem 0.8rem !important;
  background-color: var(--ion-color-step-50);
  border-radius: 7px;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.account-switcher .dropdown-menu.show {
  width: 300px;
}

.account-switcher .dropdown-menu.show .list-of-companies {
  max-height: 189px;
  overflow: scroll;
}

.account-switcher .dropdown-menu.show .list-of-companies.scrolled {
  box-shadow: inset 0px -3px 5px -4px rgba(0, 0, 0, 0.12); /* Small Bottom shadow */
}

.account-switcher .dropdown-menu.show .list-of-companies .company-drop-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.account-switcher
  .dropdown-menu.show
  .list-of-companies
  .company-drop-item.selected {
  background-color: var(--ion-color-step-50) !important;
  font-weight: 600 !important;
}

.company-drop-item .company-name,
.account-switcher .nav-link .company-name {
  max-width: 245px;
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nav-item.account-switcher .dropdown-header {
  color: var(--ion-color-step-500) !important;
  font-weight: 600;
  max-width: 290px;
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nav-item.account-switcher .dropdown-header.uppercase {
  text-transform: uppercase;
}

.nav-item.account-switcher .account-switcher-create-account {
  color: var(--visito-color-1);
}

.nav-item.account-settings .nav-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 33.59px;
  height: 33.59px;
  border: 1px solid var(--ion-color-step-100);
  border-radius: 50%;
  padding: 0 !important;
}

.nav-item.account-settings .nav-link::after {
  display: none;
}

nav {
  border-bottom: 1px solid var(--visito-border-color);
  z-index: 999 !important;
  background-color: var(--visito-background-color);
}

nav .badge {
  margin-left: 4px;
}
