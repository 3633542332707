.fullCrawlingContainer {
  max-height: 53%;
  overflow-y: auto;
}

.singleCrawlingContainer {
  max-height: 47%;
  overflow-y: auto;
}

.siteMapContainer {
  max-height: 100%;
  overflow-y: scroll;
  border-radius: 0.75rem;
  background-color: var(--ion-color-step-50);
  border: 1px solid var(--ion-color-step-50);
}

.linksContainer {
  background-color: var(--ion-color-step-0);
  padding: 0 1.5rem;
}

.linksContainer > div {
  border-bottom: 1px solid var(--ion-color-step-100);
  padding: 1rem 0;
}

.linksContainer div:last-child {
  border-bottom: none;
}

.divider {
  border-top: 1px solid var(--ion-color-step-100);
  height: 5px;
  width: 100%;
  margin: 0.5rem 0;
}
