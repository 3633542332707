.playgroundWrapper {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 75%;
  margin: 0 auto;
}

.chatWrapper {
  width: 100%;
  height: 100%;
}

.chatSkeleton {
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  background-color: var(--ion-color-step-0);
  display: flex;
  flex-direction: column;
  padding: 1rem;
}

.chatContainer {
  background-color: var(--ion-color-step-0);
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 0.5rem;
  border: 1px solid var(--visito-border-color);
}

.chatDetailContainer {
  height: 100%;
}

.chatInputContainer {
  padding: 12px;
}

.chatHeaderContainer {
  border-bottom: 1px solid var(--visito-border-color);
}

.chatHeaderContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 0.75rem 1rem;
}

.loaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.loaderContainer {
  margin-top: 5em;
  width: 250px;
}

.navContainer {
  border-right: 1px solid var(--visito-border-color);
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
}

.mainContent {
  background-color: var(--ion-color-step-50);
  position: relative;
  height: 100%;
}

.link {
  border-radius: 0.5rem;
  border: 1px solid var(--ion-color-step-50);
  transition: color 0.3s ease, background-color 0.3s ease;
  width: 100%;
}

.link:hover {
  background-color: var(--ion-color-step-50);
  color: inherit;
}

.activeLink {
  background-color: var(--ion-color-step-50);
}

.statusBadge {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.6rem;
  border: 1px solid var(--ion-color-step-250);
  padding: 0.15rem 0.4rem;
  font-size: 0.65rem;
}
